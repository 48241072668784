<input
  [type]="type"
  [name]="name"
  required
  [placeholder]="placeholder"
  [class]="twClasses"
  [ngClass]="[
    inputClass,
    ngControl.control?.dirty &&
    ngControl.control?.touched &&
    ngControl.control?.invalid
      ? 'focus:ring-red-500 ring-red-500'
      : ''
  ]"
  autocomplete=""
  [autocomplete]="autocomplete"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  (blur)="onTouched()"
  [readonly]="readonly"
/>

<!-- Handle input validations here -->
@if(ngControl.control?.dirty && ngControl.control?.touched) {
<ng-container>
  <span
    *ngIf="ngControl.control?.hasError('email')"
    class="mt-2 text-sm text-red-600"
    >Invalid email address</span
  >
  <span
    *ngIf="ngControl.control?.hasError('required')"
    class="mt-2 text-sm text-red-600"
    >Field is required</span
  >

  <span
    *ngIf="ngControl.control?.hasError('pattern')"
    class="mt-2 text-sm text-red-600"
    >Password is not strong enough</span
  >

  <span
    *ngIf="ngControl.control?.hasError('minlength')"
    class="mt-2 text-sm text-red-600"
    >Name must be at least
    {{ngControl.control?.errors?.['minlength']?.['requiredLength']}} characters
    long</span
  >
</ng-container>
}
