import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';

type InputType = 'email' | 'password' | 'reset' | 'text';
const baseClass =
  'block w-full rounded-lg border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-mango-lightGray focus:ring-2 focus:ring-inset focus:ring-mango sm:text-sm sm:leading-6  placeholder:text-base text-mango-black17 md:text-base';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './input.component.html',
  styles: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input({ required: true }) placeholder!: string;
  @Input() type: InputType = 'text';
  @Input() inputClass!: string;
  @Input() name!: string;
  @Input() autocomplete!: string;
  @Input() readonly: boolean = false;

  twClasses: string = baseClass;

  value!: string;

  onChange!: (value: string) => void;
  onTouched!: () => void;

  ngControl!: NgControl;

  constructor(private readonly injector: Injector) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  // implement interface 'ControlValueAccessor'
  writeValue(obj: string): void {
    // Value coming from the control
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    // 'registerOnChange' give us the onChange function
    this.onChange = fn;
    // the onChange method is used to pass the value generate in
  }
  registerOnTouched(fn: any): void {
    // 'registerOnTouched' give us the onTouched function
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
}
